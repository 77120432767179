import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from "react";
import AuthProviderApiJuri from '../Context/juri';

import Login from '../Views/Login';
import ForgotChange from '../Views/ForgotChange';
import Home from '../Views/Home';
import RouterWrapper from './auth';
import CartaoUpdate from '../Views/CartaoDeCreditoUpdateSignature';
import CartaoAssociate from '../Views/CartaoDeCreditoAssociate';
import Cartao from '../Views/CartaoDeCredito';
import Perfil from '../Views/Perfil'
import MudarEmail from '../Views/MudarEmail';

import DiasAtrasados from '../Views/Usuario/PontoEletronico/DiasAtrasados';
import DiasTrabalhados from '../Views/Usuario/PontoEletronico/DiasTrabalhados';
import ConfiguracaoPonto from '../Views/Usuario/PontoEletronico/ConfiguracaoPonto';
import PontoEletronico from '../Views/Usuario/PontoEletronico';
import SolicitacaoTrocaPonto from '../Views/Usuario/PontoEletronico/SolicitacoesTroca';
import HistoricoDeTroca from '../Views/Usuario/PontoEletronico/HistoricoDeTroca';

import Associados from '../Views/Administrativo/Associados';
import Terceirizados from '../Views/Administrativo/Terceirizados/';
import Planos from '../Views/Administrativo/Planos';
import Assinaturas from '../Views/Administrativo/Assinaturas';
import Negociacoes from '../Views/Administrativo/Negociacoes';
import Fundos from '../Views/Administrativo/Fundos';

import RegistroCron from '../Views/Tecnico/RegistroDeCron';

import RegistroTransacaoAssociado from '../Views/Tecnico/RegistroTransacaoAssociate';
import Hooks from '../Views/Tecnico/Hooks';
import Health from '../Views/Tecnico/Health';


import AlertasRemessa from '../Views/Alertas/AlertasRemessa';
import HistoricoUsuarios from '../Views/Usuario/HistoricoDeUsuarios';

import FolhaPagamento from '../Views/Financeiro/FolhaPagamento';
import Cobrancas from '../Views/Financeiro/Faturas/Cobranca';
import Faturas from '../Views/Financeiro/Faturas';
import Custos from '../Views/Financeiro/Custos';
import CustosFatura from '../Views/Financeiro/CustosFatura';
import Balanco from '../Views/Financeiro/Balanco';

import Saidas from '../Views/Financeiro/Saidas';

import Aprovados from '../Views/Administrativo/Acoes/Aprovadas';
import AssinaturasPendentes from '../Views/Administrativo/Acoes/Assinaturas/';
import FaturasPendentes from '../Views/Administrativo/Acoes/Faturas/';


import Forgot from '../Views/Forgot';
import TecnicoIndex from '../Views/Tecnico';
import UsuarioIndex from '../Views/Usuario';
import AlertasIndex from '../Views/Alertas';
import FinanceIndex from '../Views/Financeiro';
import Usuarios from '../Views/Usuario/Usuarios';
import AdminIndex from '../Views/Administrativo';
import ChangePassword from '../Views/ChangePassword';
import Alertas from '../Views/Alertas/Alertas/index';
import AcoesIndex from '../Views/Administrativo/Acoes';
import Associado from '../Views/Administrativo/Associado';
import Entradas from '../Views/Financeiro/Entradas/index';
import Relatorio from '../Views/Administrativo/Relatorios';
import LogsSistema from '../Views/Tecnico/LogsSistema/index';
import LogsSistemaAssociado from '../Views/Tecnico/LogsSistemaAssociado';
import LogsSistemaJuridico from '../Views/Tecnico/LogsSistemaJuridico';
import Remessa from '../Views/Financeiro/FolhaPagamento/Remessa';
import Pagamento from '../Views/Financeiro/Faturas/Pagamento/index';
import AssinaturaTemporaria from '../Views/Administrativo/AssinaturaTemporaria';
import RemessaAssociado from '../Views/Financeiro/FolhaPagamento/RemessaAssociado';
import FuncefAssinaturas from '../Views/Financeiro/FolhaPagamento/FuncefAssinaturas';
import RemessaAssociadoTransacao from '../Views/Financeiro/FolhaPagamento/RemessaAssociadoTransacao';

import ProdutosInvestimento from '../Views/Financeiro/ProdutosInvestimento';
import ProdutosInvestimentoAporte from '../Views/Financeiro/ProdutosInvestimentoAporte';
import ProdutosInvestimentoAporteExtrato from '../Views/Financeiro/ProdutosInvestimentoAporteExtrato';

import Ferias from '../Views/Usuario/Ferias';
import FeriasFuncionarios from '../Views/Usuario/Ferias/FeriasFuncionarios';
import PainelFerias from '../Views/Usuario/Ferias/PainelFerias';
import PainelVendaFerias from '../Views/Usuario/Ferias/PainelVendaFerias';

import Terceirizado from '../Views/Administrativo/Terceirizado';


import Importacoes from '../Views/Financeiro/importacao/index'
import ImportacaoView from '../Views/Financeiro/importacao/importacoes'
import ImportacaoFaturasView from '../Views/Financeiro/importacao/faturaImportacoes'
import ImportacaoFalhasView from '../Views/Financeiro/importacao/falhasImportacoes'

import Juridico from '../Views/Juridico'
import JuridicoUsuarios from '../Views/Juridico/Usuarios'
import JuridicoEscritorios from '../Views/Juridico/Escritorios'
import JuridicoProcessos from '../Views/Juridico/Processos'
import JuridicoProcesso from '../Views/Juridico/Processo'
import JuridicoProcessoPainel from '../Views/Juridico/ProcessoPainel'
import JuridicoProcessoView from '../Views/Juridico/ProcessoView'
import JuridicoProcessoCheckAndSend from '../Views/Juridico/ProcessoCheck'
import JuridicoProcessoTribunal from '../Views/Juridico/Tribunais'
import JuridicoProcessoVara from '../Views/Juridico/Tribunais/Varas'

import JuridicoAcoes from '../Views/Juridico/Acoes'
import JuridicoAcao from '../Views/Juridico/Acoes/Acao'
import JuridicoAcaoCompletarCadastro from '../Views/Juridico/Acoes/AcaoCompletarCadastro'

import JuridicoAssociadosProcesso from '../Views/Juridico/AssociadosProcesso'


import ComunicaCao from '../Views/Comunicacao'
import ComunicaCaoNoticias from '../Views/Comunicacao/Noticias'
import ComunicaCaoFaq from '../Views/Comunicacao/FAQ'
import ComunicaCaoAssociados from '../Views/Comunicacao/Associados'
import ComunicaCaoAssociadosLogados from '../Views/Comunicacao/AssociadosLogados'
import ComunicacaoRecuperacaoSenha from '../Views/Comunicacao/RecuperacaoSenha'

import Projetos from '../Views/Projetos'
import Projeto from '../Views/Projeto'

const NotFound = () => {
    return (
        <div class="bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center">
            <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl">
                <p class="text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-gray-300">404</p>
                <p class="text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 mt-4">Página não encontrada</p>
                <p class="text-gray-500 mt-4 pb-4 border-b-2 text-center">A página que você procura não pôde ser encontrada</p>
                <a href="/home" class="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 mt-6 rounded transition duration-150" title="Return Home">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
                    </svg>
                    <span>Voltar para Home</span>
                </a>
            </div>
        </div>
    )
}

const RouterAcoes = () => {
    return (
        <Routes>
            <Route path='/' element={<AcoesIndex />} />
            <Route path='/aprove' element={<RouterWrapper component={Aprovados} isPrivate={true} />} />
            <Route path='/assinaturasPendentes' element={<RouterWrapper component={AssinaturasPendentes} isPrivate={true} />} />
            <Route path='/faturasPendentes' element={<RouterWrapper component={FaturasPendentes} isPrivate={true} />} />
        </Routes>
    )
}

const RouterAdmin = () => {

    return (
        <Routes>
            <Route path='/' element={<AdminIndex />} />
            <Route path='/associado/:id' element={<RouterWrapper component={Associado} isPrivate={true} />} />
            <Route path='/associados' element={<RouterWrapper component={Associados} isPrivate={true} />} />
            <Route path='/assinaturas' element={<RouterWrapper component={Assinaturas} isPrivate={true} />} />
            <Route path='/fundos' element={<RouterWrapper component={Fundos} isPrivate={true} />} />
            <Route path='/negociacoes' element={<RouterWrapper component={Negociacoes} isPrivate={true} />} />
            <Route path='/planos' element={<RouterWrapper component={Planos} isPrivate={true} />} />
            <Route path='/terceirizado/:id' element={<RouterWrapper component={Terceirizado} isPrivate={true} />} />
            <Route path='/terceirizados' element={<RouterWrapper component={Terceirizados} isPrivate={true} />} />
            <Route path='/acoesIndex/*' element={<RouterWrapper component={RouterAcoes} isPrivate={true} />} />
            <Route path='/report' element={<RouterWrapper component={Relatorio} isPrivate={true} />} />
            <Route path='/tempSign' element={<RouterWrapper component={AssinaturaTemporaria} isPrivate={true} />} />
        </Routes>
    )
}

const RouterFatura = () => {

    return (
        <Routes>
            <Route path='/' element={<Faturas />} />
            <Route path="/cobranca" element={<RouterWrapper component={Cobrancas} isPrivate={true} />} />
            <Route path="/pagamento" element={<RouterWrapper component={Pagamento} isPrivate={true} />} />
        </Routes>
    )
}

const RouterRemessa = () => {

    return (
        <Routes>
            <Route path='/' element={<FolhaPagamento />} />
            <Route path='/remessa' element={<Remessa />} />
            <Route path='/remessaAssociado' element={<RemessaAssociado />} />
            <Route path='/remessaAssociadoTransacao' element={<RemessaAssociadoTransacao />} />
            <Route path='/funcefAssinaturas' element={<FuncefAssinaturas />} />
        </Routes>
    )
}

const RouterImportacao = () => {

    return (
        <Routes>
            <Route path='/' element={<Importacoes />} />
            <Route path='/importacoes' element={<ImportacaoView />} />
            <Route path='/faturas' element={<ImportacaoFaturasView />} />
            <Route path='/errosImportacoes' element={<ImportacaoFalhasView />} />
        </Routes>
    )
}


const RouterFinance = () => {

    return (
        <Routes>
            <Route path='/' element={<FinanceIndex />} />
            <Route path="/balanco" element={<RouterWrapper component={Balanco} isPrivate={true} />} />
            <Route path="/custos" element={<RouterWrapper component={Custos} isPrivate={true} />} />
            <Route path="/custos/faturas/:id" element={<RouterWrapper component={CustosFatura} isPrivate={true} />} />
            <Route path="/folhaPagamento/*" element={<RouterWrapper component={RouterRemessa} isPrivate={true} />} />
            <Route path="/faturas/*" element={<RouterWrapper component={RouterFatura} isPrivate={true} />} />
            <Route path="/entradas" element={<RouterWrapper component={Entradas} isPrivate={true} />} />
            <Route path="/saidas" element={<RouterWrapper component={Saidas} isPrivate={true} />} />
            <Route path="/importacao/*" element={<RouterWrapper component={RouterImportacao} isPrivate={true} />} />
            <Route path="/produtosinvestimento" element={<RouterWrapper component={ProdutosInvestimento} isPrivate={true} />} />
            <Route path="/produtosinvestimento/aporte/:id" element={<RouterWrapper component={ProdutosInvestimentoAporte} isPrivate={true} />} />
            <Route path="/produtosinvestimento/extrato/:id" element={<RouterWrapper component={ProdutosInvestimentoAporteExtrato} isPrivate={true} />} />
        </Routes>
    )
}

const RouterUsuario = () => {

    return (
        <Routes>
            <Route path='/' element={<UsuarioIndex />} />
            <Route path="/usuarios" element={<RouterWrapper component={Usuarios} isPrivate={true} />} />
            <Route path="/pontoEletronicoIndex/*" element={<RouterWrapper component={RouterPontoEletronico} isPrivate={true} />} />
            <Route path="/feriasIndex/*" element={<RouterWrapper component={RouterFerias} isPrivate={true} />} />
            <Route path="/historicousuarios" element={<RouterWrapper component={HistoricoUsuarios} isPrivate={true} />} />
        </Routes>
    )
}

const RouterFerias = () => {

    return (
        <Routes>
            <Route path='/' element={<Ferias />} />
            <Route path="/feriasFuncionarios" element={<RouterWrapper component={FeriasFuncionarios} isPrivate={true} />} />
            <Route path="/painelFerias" element={<RouterWrapper component={PainelFerias} isPrivate={true} />} />
            <Route path="/painelVendaFerias" element={<RouterWrapper component={PainelVendaFerias} isPrivate={true} />} />
        </Routes>
    )
}


const RouterPontoEletronico = () => {

    return (
        <Routes>
            <Route path='/' element={<PontoEletronico />} />
            <Route path="/configuracaoPonto" element={<RouterWrapper component={ConfiguracaoPonto} isPrivate={true} />} />
            <Route path="/diasAtrasados" element={<RouterWrapper component={DiasAtrasados} isPrivate={true} />} />
            <Route path="/diasTrabalhados" element={<RouterWrapper component={DiasTrabalhados} isPrivate={true} />} />
            <Route path="/solicitacaoDeTroca" element={<RouterWrapper component={SolicitacaoTrocaPonto} isPrivate={true} />} />
            <Route path="/historicooDeTroca" element={<RouterWrapper component={HistoricoDeTroca} isPrivate={true} />} />
        </Routes>
    )
}

const RouterAlerta = () => {

    return (
        <Routes>
            <Route path='/' element={<AlertasIndex />} />
            <Route path="/alertas" element={<RouterWrapper component={Alertas} isPrivate={true} />} />
            <Route path="/alertasremessa" element={<RouterWrapper component={AlertasRemessa} isPrivate={true} />} />
        </Routes>
    )
}

const RouterTecnico = () => {

    return (
        <Routes>
            <Route path='/' element={<TecnicoIndex />} />
            <Route path="/registrocron" element={<RouterWrapper component={RegistroCron} isPrivate={true} />} />
            <Route path="/logsSistema" element={<RouterWrapper component={LogsSistema} isPrivate={true} />} />
            <Route path="/logsSistemaAssociado" element={<RouterWrapper component={LogsSistemaAssociado} isPrivate={true} />} />
            <Route path="/logsSistemaJuridico" element={<RouterWrapper component={LogsSistemaJuridico} isPrivate={true} />} />
            <Route path="/transacaoassociadoremessa" element={<RouterWrapper component={RegistroTransacaoAssociado} isPrivate={true} />} />
            <Route path="/hooks" element={<RouterWrapper component={Hooks} isPrivate={true} />} />
            <Route path="/health" element={<RouterWrapper component={Health} isPrivate={true} />} />
        </Routes>
    )
}

//Remover
const RouterProcesso = () => {

    return (
        <Routes>
            <Route path='/' element={<JuridicoProcessoPainel />} />
        </Routes>
    )

}

const RouterAcao = () => {

    return (
        <Routes>
            <Route path='/' element={<JuridicoAcoes />} />
            <Route path='/completarCadastro/:id' element={<JuridicoAcaoCompletarCadastro />} />
            <Route path='/acao/:id' element={<JuridicoAcao />} />
        </Routes>
    )

}


const RouterJuridico = () => {

    return (
        <AuthProviderApiJuri>
            <Routes>
                <Route path='/' element={<Juridico />} />
                <Route path='/acoes/*' element={<RouterAcao />} />
                <Route path='/usuarios' element={<JuridicoUsuarios />} />
                <Route path='/escritorios' element={<JuridicoEscritorios />} />
                <Route path='/tribunais' element={<JuridicoProcessoTribunal />} />
                <Route path='/tribunais/:id/varas' element={<JuridicoProcessoVara />} />
                <Route path='/processosAssociados' element={<JuridicoAssociadosProcesso />} />
                <Route path='/processos' element={<JuridicoProcessos />} />
                <Route path='/processos/cadastro/:id' element={<JuridicoProcesso />} />
                <Route path='/processo/:id/*' element={<RouterProcesso />} />
            </Routes>
        </AuthProviderApiJuri>
    )
}

const RouterComunicacao = () => {

    return (
        <Routes>
            <Route path='/' element={<ComunicaCao />} />
            <Route path='/noticias' element={<ComunicaCaoNoticias />} />
            <Route path='/faq' element={<ComunicaCaoFaq />} />
            <Route path='/associados' element={<ComunicaCaoAssociados />} />
            <Route path='/associadosLogados' element={<ComunicaCaoAssociadosLogados />} />
            <Route path='/recuperacaoSenha' element={<ComunicacaoRecuperacaoSenha />} />
        </Routes>
    )
}


const Routers = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])


    return (
        <Routes>
            <Route path="/" element={<RouterWrapper component={Login} isPrivate={false} />} />
            <Route path='/vizualizacao/processo/:url' element={<JuridicoProcessoView />} />
            <Route path='/verificarEnviar/processo/:token/:idstep' element={<JuridicoProcessoCheckAndSend />} />
            <Route path="/changepassword/:token" element={<RouterWrapper component={ForgotChange} isPrivate={false} />} />
            <Route path="/change/:token" element={<RouterWrapper component={ChangePassword} isPrivate={false} />} />
            <Route path="/home" element={<RouterWrapper component={Home} isPrivate={true} />} />
            <Route path="/comunicacaoIndex/*" element={<RouterWrapper component={RouterComunicacao} isPrivate={true} />} />
            <Route path="/adminIndex/*" element={<RouterWrapper component={RouterAdmin} isPrivate={true} />} />
            <Route path="/financeIndex/*" element={<RouterWrapper component={RouterFinance} isPrivate={true} />} />
            <Route path="/juridicoIndex/*" element={<RouterWrapper component={RouterJuridico} isPrivate={true} />} />
            <Route path="/usuarioIndex/*" element={<RouterWrapper component={RouterUsuario} isPrivate={true} />} />
            <Route path="/alertaIndex/*" element={<RouterWrapper component={RouterAlerta} isPrivate={true} />} />
            <Route path="/tecnicoIndex/*" element={<RouterWrapper component={RouterTecnico} isPrivate={true} />} />
            <Route path="/projetos" element={<RouterWrapper component={Projetos} isPrivate={true} />} />
            <Route path="/projeto/:id" element={<RouterWrapper component={Projeto} isPrivate={true} />} />
            <Route path="/associado" element={<RouterWrapper component={Associado} isPrivate={true} />} />
            <Route path="/cartao/:token" element={<RouterWrapper component={Cartao} isPrivate={false} />} />
            <Route path="/cartaoAssociate/:id" element={<RouterWrapper component={CartaoAssociate} isPrivate={false} />} />
            <Route path="/cartaoSignature/:id/:token" element={<RouterWrapper component={CartaoUpdate} isPrivate={false} />} />
            <Route path="/changeMail/:token" element={<RouterWrapper component={MudarEmail} isPrivate={false} />} />
            <Route path="/perfil" element={<RouterWrapper component={Perfil} isPrivate={true} />} />
            <Route path="/forgot" element={<RouterWrapper component={Forgot} isPrivate={false} />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Routers